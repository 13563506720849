import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "scroll" ]

  smooth(event) {
    $('.collapse.show').removeClass('show')
    const offset = this.scrollTarget.dataset['offset']

    const a = $(event.target).closest('a')
    if (a) {
      const url = a.attr("href")
      const anchor = url.substring(url.indexOf("#") + 1)
      if ($('#' + anchor).length) {
        $("html, body").animate({ scrollTop: $('#' + anchor).offset().top - offset }, 800)
      }
    }

    // const collapseTriggerLink = $(event.target).data("trigger-click")
    // const collapse = $(event.target).data("collapse")
    //
    // if (collapse) {
    //   $(collapse).removeClass().addClass('collapse')
    // }
    //
    // if (collapseTriggerLink) {
    //   $(collapseTriggerLink).addClass('collapsed')
    // }
  }
}
